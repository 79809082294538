const FormField = ({label, type, options, onClick, onChange, onBlur, onFocus, onKeyDown, placeholder, defaultValue, required, name}) => {
    type = type ?? "input";
    let input = "";
    options = options ?? [];
    const option = ({valueName,value}) => {
        return <option value={valueName ?? value}>{value ?? valueName}</option>
    }


    switch (type) {
        case "select":
            input = <select className={"enter-2-tab"} required={required ? "required" : ''} name={name}
                            onClick={() => onClick}
                            onBlur={onBlur}
                            onFocus={onFocus} placeholder={placeholder}
                            onChange={onChange}
                            value={defaultValue}
                            onKeyDown={onKeyDown}
            >
                {options.map((o) => (option(o)))}
            </select>
            break;
        case "text":
            input =
                <textarea required={required ? "required" : ''} name={name} onClick={() => onClick}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          onChange={onChange}
                          onKeyDown={onKeyDown}
                          placeholder={placeholder} value={defaultValue}
                />
            break;
        case "input":
        default:
            input = <input className={"enter-2-tab"} required={required ? "required" : ''} name={name}
                           onClick={() => onClick}
                           onBlur={onBlur}
                           onFocus={onFocus}
                           onKeyDown={onKeyDown}
                           onChange={onChange}
                           placeholder={placeholder} value={defaultValue}

            />

            break;
    }
    return <div className={"field field-name-" + name}>
        <div className={"field-name"}>{label}{required ?
            <div className={"field-required"}>*</div> : ''}</div>
        <div className={"field-input"}>{input}</div>
    </div>
}

export default FormField;