import React from "react";
import ContactForm from "../assets/ContactForm";

const Contact = () => {
    window.document.title = 'Contact >> BlokChainr.com';

    return <div className={"page"}>
        <div className={"layout"}>
        <div className={"content"}>
                <ContactForm/>
        </div>
        </div>
    </div>
}

export default Contact;