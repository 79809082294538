import Particles from "react-tsparticles";
import React, {useCallback} from "react";
import {loadFull} from "tsparticles";
import {options} from "./particleOptions";

const PageBox = ({title, content, icon, minHeight, minWidth,particles}) => {


    const particlesInit = useCallback(async (engine) => {

        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);

    }, []);
    const particlesLoaded = useCallback(async (container) => {

        await console.log(container);

    }, []);

    minWidth = minWidth ?? "100%";
    minHeight = minHeight ?? "100%";
    return <div className="home-box page-box" style={{overflow: "hidden", position: "relative", zIndex: 0}}>
        {particles ? <Particles
            style={{
                position: "absolute",
                zIndex: 0,
                opacity: 0.2,
                maxHeight: minHeight,
                minHeight: minHeight,
                height: minHeight
            }}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={options}
        /> : ''}
        <div className="home-box-title" style={{zIndex: 1}}>
            <div className="page-box-icon" style={{zIndex: 1}}>{icon}</div>
            {title}</div>
        <div className="home-box-content" style={{zIndex: 1}}>{content}</div>
    </div>
}

export default PageBox;