import React, {useEffect, useRef, useState} from "react";
import {AiOutlinePlayCircle} from 'react-icons/ai';

const Video = ({movSrc, webmSrc, movSafariSrc, mov, webm, videoEffect, mute = false, controls = false}) => {

    const prevRef = useRef(false);
    console.log('prev',prevRef);
    const [videoPlayed, setVideoPlayed] = useState(  prevRef.current);
    const [videoAutoStart, setVideoAutoStart] = useState(  false);



    useEffect(
        () => {


            if (videoEffect) {
                videoEffect();
            }
            if (window.innerWidth > 768) {
                //https://convertio.co/download/
                setTimeout(() => {
                    if (!videoPlayed && !videoAutoStart) {
                        prevRef.current = true;
                        setVideoPlayed(true);
                        setVideoAutoStart(true);
                        document.getElementById("video").play();

                    }
                }, 4000);

                // if safari browser then give video opacity 0.8
                if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                    document.getElementById("video").style.opacity = 0.7;
                }

                //on video element touch or window click stop video
                document.getElementById("video").addEventListener('touchstart', () => {
                        if (videoPlayed) {

                            document.getElementById("video").pause();
                        } else {
                            prevRef.current = true;
                            setVideoPlayed(true);
                            setVideoAutoStart(true);

                            document.getElementById("video").play();
                        }

                    }
                );
                document.getElementById("video").addEventListener('click', () => {
                        if (videoPlayed ) {

                            document.getElementById("video").pause();
                        } else  {
                            prevRef.current = true;
                            setVideoPlayed(true);
                            setVideoAutoStart(true);
                            document.getElementById("video").play();
                        }

                    }
                );
            }


        }
    )

    const mobileVideo = () => {
        //set element with id video to full screen
        const video = document.getElementById("video");
        video.style.display = "block";
        video.style.width = "100%";
        video.style.height = "100%";
        video.style.position = "absolute";
        video.style.top = "0";
        video.style.left = "0";
        video.style.zIndex = "4";
        video.style.objectFit = "cover";
        video.style.background = "black";
        video.minHeight = window.innerHeight;
        //set element with class video-chroma to full screen
        const videoChroma = document.getElementsByClassName("video-chroma")[0];
        videoChroma.style.width = "100%";
        videoChroma.style.background = "black";
        videoChroma.style.height = "100%";
        videoChroma.style.top = "0";
        playVideo();
    }

    const playVideo = () => {
        document.getElementById("video").play();
    }


    movSrc = movSrc ?? "https://api.blokchainr.com?w=introfade_VP9_H.265.mp4";
    mov = mov ?? <source src={movSrc} type='video/mp4; codecs="hvc1"'/>;
    movSafariSrc = movSafariSrc ?? "https://api.blokchainr.com?w=introfade-black_H.265.mp4";
    let movMob = <source src={movSafariSrc} />;
    webmSrc = webmSrc ?? "https://api.blokchainr.com?w=introfade_VP9.webm";
    webm = webm ?? <source src={webmSrc} type="video/webm"/>;
    if (window.innerWidth < 768) {
        return <div className={'video-chroma'} style={{borderRadius: "15px"}}>
            <video id="video" autoPlay={false} controls={true} playsInline={true} muted={mute}
                   style={{display: "none"}}>
                {movMob}
                {webm}
            </video>
            <div>
                <AiOutlinePlayCircle onClick={() => {
                    mobileVideo();
                }} style={{fontSize: "90px"}}/>
            </div>
        </div>
    }
    return <div className={'video-chroma'}>
        <div>
            <video id="video" autoPlay={false} controls={controls} playsInline={true} muted={mute} >
                {mov}
                {webm}
            </video>
            {videoPlayed ?
                <div style={{
                    width: "100%",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    top: "-60px",
                    position: "relative",
                    height: "0"
                }}>
                    <AiOutlinePlayCircle onClick={() => {
                        playVideo();
                    }} style={{
                        fontSize: "40px",
                        position: "relative",
                        textAlign: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textShadow: "0 0 10px #000",
                        color: "#fff"
                    }}/></div> : null}
        </div>
    </div>
}


export {Video} ;