import Banner from "../assets/Banner";
import React from "react";
import HomeBoxes from "../assets/HomeBoxes";

const Home = () => {
    window.document.title = 'AI + Web3 = BlokChainr.com';
    return <div>
        <Banner/>
        <div className={"layout"}>
            <div className={"content"}>
                <HomeBoxes/>
            </div>
        </div>
    </div>
}

export default Home;