


const HomeBox = (props) => {
    return <div className="home-box">
        <div className="home-box-title">
            <div className="home-box-icon">{props.icon}</div>
            {props.title}</div>
        <div className="home-box-content">{props.content}</div>
    </div>
}

export default HomeBox;