import PageBox from "./PageBox";

const PageBoxes = ({boxes}) => {


    let allBoxes = boxes.map((f,index) => {

        return <PageBox key={index}  {...f} />
    });

    return <div className="home-boxes">
        {allBoxes}
    </div>
}

export default PageBoxes;