import {LogoLight} from "../assets/Logo";
import {NavLink} from "react-router-dom";


const Footer = () => {
    let year = new Date().getFullYear();
    return  <div id="footer-layout">
                <footer>
                    <div><nav><NavLink to={"/privacy"}>Privacy Policy</NavLink></nav></div>
                    <div className={"copy-right"}>
                        &copy; {year} <LogoLight w={95}/> &copy; BlokChainr Limited (14353757) also trading as <b><a href={'http://www.neurify.ai'} target={'_blank'}  rel="noreferrer">Neurify AI</a></b> | <b><a href={'http://www.numochat.com'} target={'_blank'}  rel="noreferrer">NumoChat</a></b> | <b><a href={'http://www.earthling.id'} target={'_blank'}  rel="noreferrer">EarthlingID</a></b> - All Rights Reserved
                    </div>
                </footer>
            </div>
}

export default Footer;