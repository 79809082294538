import React, {useEffect} from 'react';
import './App.css';
import Home from "./pages/Home";
import About from "./pages/About";
import Neurify from "./pages/Neurify";
import NumoChat from "./pages/NumoChat";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import {Routes, Route, NavLink} from "react-router-dom";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import Resources from "./pages/Resources";
import ReactGA from 'react-ga';
import Privacy from "./pages/Privacy";
import GimmeFeels from "./pages/GimmeFeels";
import Renound from "./pages/Renound";
import GoerliFaucet from "./pages/GoerliFaucet";
import EarthlingID from "./pages/EarthlingID";

const TRACKING_ID = "G-8ZM3XHHFBL";

ReactGA.initialize(TRACKING_ID);

//https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics.html
const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <div>
            <div id="layout">
                <Header/>
                <Routes>
                    <Route exact path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/resources" element={<Resources/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/gimmefeels" element={<GimmeFeels/>}/>
                    <Route path="/renound" element={<Renound/>}/>
                    <Route path="/faucets" element={<GoerliFaucet />}/>
                    <Route path="/neurify-ai" element={<Neurify />}/>
                    <Route path="/earthling-id" element={<EarthlingID />}/>
                    <Route path="/numochat" element={<NumoChat />}/>
                </Routes>
                <CookieConsent
                    location="bottom"
                    buttonText="I Understand"
                    cookieName="cookieConsent"
                    style={{background: "#561bde",color:"white"}}
                    buttonStyle={{
                        color: "#2c70f8",
                        fontSize: "18px",
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        border: "solid 1px #2c70f8",
                        minHeight: "40px",
                        paddingLeft: "30px",
                        paddingRight: "30px"

                    }}
                    expires={10000}
                >
                    We use cookies! Hope that's ok?
                    <span style={{fontSize: "12px"}}> <NavLink style={{color:"#f2f2f2"} } to={"/privacy"}> Please See Our Privacy Policy on how we handle data </NavLink> </span>
                </CookieConsent>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
