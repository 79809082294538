import Banner from "../assets/Banner";
import React from "react";
import HomeBoxes from "../assets/HomeBoxes";

const EarthlingID = () => {
    window.document.title = 'Earthling.ID >> BlokChainr.com';
    return <div>
        <Banner content={<b>Earthling.ID</b>}/>
        <div className={"layout"}>
            <div className={"content"}>
                <HomeBoxes/>
            </div>
        </div>
    </div>
}

export default EarthlingID;