export let options = {
    destroyed: false,
    fullScreen: false,
    background: {
        color: {
            value: "transparent",
        },
    },
    misplaced: false,
    fpsLimit: 60,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
            onHover: {
                enable: true,
                mode: "repulse",
            },
            resize: true,
        },
        modes: {
            push: {
                quantity: 1,
            },
            repulse: {
                distance: 25,
                duration: 0.03,
            },
        },
    },
    particles: {
        color: {
            value: "#ffffff",
        },
        links: {
            color: "#ffffff",
            distance: 300,
            enable: true,
            opacity: 1,
            width: 1,
        },
        collisions: {
            enable: false,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 0.35,
            straight: true,
        },
        number: {
            density: {
                enable: true,
                area: 250,
            },
            value: 13,
        },
        opacity: {
            value: 0.5,
        },
        pathRotation: false,
        shape: {
            type: "image",
            image: {
                src: "/img/blokchainr-logo-light.svg",
                width: 65,
                height: 50
            },

        },
        size: {
            value: {min: 5, max: 10},
        },
    },
    detectRetina: true,
    wobble: {
        angle: 40,
        angleSpeed: 10,
        moveSpeed: 20,
    }
};
//https://vincentgarreau.com/particles.js/
