import React from "react";

const Renound = () => {
    window.document.title = 'Renound >> BlokChainr.com';

    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
               <h1>Project: Renound</h1>
                <img style={{marginLeft:"auto",marginRight:"auto",borderRadius:"7px", maxWidth:"80%"}} src={"/img/renound.png"} alt={"Renound"}/>
            </div>
        </div>
    </div>
}


export default Renound;