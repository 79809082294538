import React, {useState} from 'react';
import {Logo} from "../assets/Logo";
import {CgMenuGridR,CgClose} from 'react-icons/cg';
import {Link} from "react-router-dom";
import Nav from "./Nav";

const Header = () => {
    const [sideBar, setSideBar] = useState(false);
    const showSideBar = () => setSideBar(!sideBar);

    return <div>
        <header>
<div className={"layout"} >
            <Link to="/" onClick={() => setSideBar(false)}>
                <div className="logo-holder">
                    <Logo w={200}/>
                </div>
            </Link>
            <div className={!sideBar?"nav-holder":"nav-holder active"}>
                <Nav active={!sideBar} onClick={() => showSideBar()}/>
            </div>
            <Link to="#" className={sideBar?"nav-button":"nav-button active"} onClick={() => showSideBar()}>
                <CgMenuGridR/>
            </Link>
            <Link to="#" className={!sideBar?"nav-button-close":"nav-button-close active"} onClick={() => showSideBar()}>
                <CgClose/>
            </Link>
</div>

        </header>

    </div>
}

export default Header;