import {NavLink} from "react-router-dom";

const Nav = (props) => {

    return <div className="nav">
        <nav>
            <ul>
                <li><NavLink end to="/" onClick={props.onClick}>Home</NavLink></li>
                <li><NavLink to="/about" onClick={props.onClick}>About</NavLink></li>
                <li><NavLink to="/resources" onClick={props.onClick}>Resources</NavLink></li>
                <li><NavLink to="/faucets" onClick={props.onClick}>Faucets</NavLink></li>
                <li><NavLink to="/contact" onClick={props.onClick}>Contact</NavLink></li>

            </ul>
        </nav>
    </div>

}

export default Nav;