import React from "react";
import PageBoxes from "../assets/PageBoxes";
import HomeBoxes from "../assets/HomeBoxes";
import {LogoLight} from "../assets/Logo";


const About = () => {
    window.document.title = 'About >> BlokChainr.com';
    let staff = [
        {
            title: <div><div className={"profile"} style={{backgroundImage:"url(/img/john.png)"}} alt={"John R Saunders | Founder & CEO"} ></div><LogoLight w={120} h={40} /><h2>John R Saunders - Founder & CEO</h2></div>,
            content: <><p>Founded by John R Saunders in 2022, Blokchainr.com is a research and development-focused startup that is poised to make a major impact in the Web3 and AI/Blockchain industries. With a lean and agile approach, the company aims to bring innovative products and services to market that will drive adoption of these cutting-edge technologies.</p><p>John Saunders, the founder of Blokchainr.com, brings extensive experience and expertise to the table. As a former Head of Tech and Principal Software Engineer in the affiliate marketing sector, John has a proven track record of delivering large-scale financial applications that meet the needs of a rapidly-evolving market. This experience, combined with his passion for innovation, makes him the ideal leader to guide Blokchainr.com to success.</p><p>If you're looking for an opportunity to invest in a dynamic and innovative startup, Blokchainr.com may be just what you're looking for. With a visionary founder, a strong value proposition, and a proven track record of success, this company is poised to make a significant impact in the Web3 and AI/Blockchain industries.</p><p>We invite you to learn more about Blokchainr.com and its exciting potential. Contact us today to schedule a meeting and find out how you can be a part of this promising startup.</p></>,
            particles: true,
            minHeight:"300px"
        }
    ];


    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
                <h1>About BlokChainr.com</h1>
                <PageBoxes boxes={staff}/>
                <HomeBoxes/>
            </div>
        </div>
    </div>
}

export default About;