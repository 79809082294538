import HomeBox from "./HomeBox";
import {SiHiveBlockchain, SiWeb3Dotjs} from 'react-icons/si';
// import {MdArchitecture} from 'react-icons/md';
import {TbStack3} from 'react-icons/tb';
// import {ImLab} from 'react-icons/im';
// import {FaMedapps} from 'react-icons/fa';
import {HiChip} from 'react-icons/hi';
import {BiCube} from 'react-icons/bi';
import {GiEyeTarget} from 'react-icons/gi';

const HomeBoxes = (props) => {
    let boxes = props.boxes ?? [
        {
            title: "Engineering",
            content: <><b>Blockchain & AI</b> based Technology is the core of our Software Engineering.  Our focus is on secure, innovative and <i>ethical</i> Web3 and AI Projects.</>,
            icon: <SiHiveBlockchain/>
        },
        {
            title: "Architecture",
            content: <>Utilising years of experience with high profile large scale projects we facilitate structuring innovative Web3 and AI Architectures.</>,
            icon: <TbStack3/>
        },
        {
            title: "Research",
            content: <>Researching the future of Blockchain and Web3 and AI, investing in new; Protocols, Blockchains, Oracle Systems, and innovative use of Smart Contract Technology.</>,
            icon: <HiChip/>
        },
        {
            title: "Our Products",
            content: <>We are developing several in-house decentralized & hybrid applications.  We're
                building <b>AI Assistants, Educational AI, DeFi, Dating, NFT Music and Social Networking.</b></>,
            icon: <BiCube/>
        },
        {
            title: "A.I + Web3",
            content: <>After Developing in Web2 for decades we are now embracing the future of the Web and all the benefits AI and 
                Web3 decentralized <i>trustless</i> applications can bring.</>,
            icon: <SiWeb3Dotjs/>
        },
        {
            title: "Vision",
            content: <>Our Vision is to make Web3 and AI a useful, interesting, secure and ethical place where users can implicitly trust the applications they are using.</>,
            icon: <GiEyeTarget/>
        }
    ];

    let allboxes = boxes.map((f, index) =>
        <HomeBox key={index}
                 title={f.title}
                 content={f.content}
                 icon={f.icon}
        />);

    return <div className="home-boxes">
        {allboxes}
    </div>
}

export default HomeBoxes;