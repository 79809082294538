import React from 'react';

/**
 *
 * @param w
 * @param h
 * @param type
 * @return {JSX.Element}
 * @constructor
 */
export function Logo({w,h,type}) {
    let width = w ?? 120;
    let height = h ?? (width / 5.3);
    let margin = height / 5;
    return <div className={"logo " + (type ?? "dark")}
                style={{minWidth: width + "px", minHeight: height + "px", marginTop: margin + "px"}}></div>
}

/**
 *
 * @param w
 * @param h
 * @param type
 * @return {JSX.Element}
 * @constructor
 */
export const LogoLight = ({w,h,type}) => {
    let width = w ?? 120;
    let height = h ?? (width / 5.3);
    let margin = height / 5;
    return <div className={"logo " + (type ?? "light")}
                style={{minWidth: width + "px", minHeight: height + "px", marginTop: margin + "px"}}></div>
}



