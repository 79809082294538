import React from "react";
import {BsArrowRightCircleFill} from 'react-icons/bs';
import {SiChainlink, SiSolidity,SiBinance,SiBitcoin} from 'react-icons/si';
import {FaEthereum,FaMountain} from 'react-icons/fa';
import {VscLayersDot} from 'react-icons/vsc'
import {CgInfinity} from 'react-icons/cg';
import {TbHelicopterLanding} from 'react-icons/tb';
import {BiNetworkChart} from 'react-icons/bi';

import HomeBoxes from "../assets/HomeBoxes";

const Resources = () => {
    window.document.title = 'Resources >> BlokChainr.com';

    let resources = [
        {
            title: "L2 Bitcoin",
            content: ["The Lightning Network is a peer-to-peer payment network. It leverages payment channels anchored on the Bitcoin blockchain to enable near instant and low-cost settlement of bitcoin between participants. ",
                <a href="https://docs.lightning.engineering/" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <SiBitcoin/>
        },
        {
            title: "Ethereum",
            content: ["Ethereum (ETH) is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications. ",
                <a href="https://ethereum.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <FaEthereum/>
        },
        {
            title: "Chainlink",
            content: ["Chainlink decentralized oracle networks provide tamper-proof inputs, outputs, and computations to support advanced smart contracts on any blockchain. ",
                <a href="https://chain.link" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <SiChainlink/>
        },
        {
            title: "Solidity",
            content: ["Solidity is a statically-typed curly-braces programming language designed for developing smart contracts that run on Ethereum. ",
                <a href="https://soliditylang.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <SiSolidity/>
        },
        {
            title: "Internet Computer",
            content: ["The Internet Computer, can replace traditional IT and enable a new generation of Web3 apps and services running entirely on-chain. ",
                <a href="https://internetcomputer.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <CgInfinity/>
        },
        {
            title: "BNB Chain",
            content: ["BNB Chain is very suitable for building large scale dApps with massive use bases. ",
                <a href="https://www.bnbchain.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <SiBinance/>
        },
        {
            title: "Avalanche",
            content: ["Avalanche, is focused on accelerating the adoption and growth of its novel “subnet” functionality. ",
                <a href="https://www.avax.network" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <FaMountain/>
        },
        {
            title: "Hedera",
            content: ["Hedera, the most used, sustainable, enterprise-grade public network for the decentralized economy, today announced a significant upgrade through the mainnet launch of the Hedera Smart Contracts 2.0. ",
                <a href="https://hedera.com" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <TbHelicopterLanding/>
        },
        {
            title: "Polkadot",
            content: ["Polkadot is built to connect and secure unique blockchains, whether they be public, permissionless networks, private consortium chains, or other Web3 technologies. ",
                <a href="https://polkadot.js.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <VscLayersDot/>
        },
        {
            title: "Dfinity",
            content: ["Dfinity are a Swiss-based not-for-profit organization with the largest R&D team in blockchain. ",
                <a href="https://dfinity.org" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <CgInfinity/>
        },
        {
            title: "Cranq",
            content: <>CRANQ is the graphical and intuitive IDE designed for discovery, and re-use of existing code and smart contracts.  <a href="https://cranq.io/" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a></>,
            icon: <BiNetworkChart />
        }
    ];

    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
                <h1>Resources</h1>
                <HomeBoxes boxes={resources}/>
            </div>
        </div>
    </div>
}

export default Resources;