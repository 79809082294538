import React from "react";

const GimmeFeels = () => {
    window.document.title = 'GimmeFeels >> BlokChainr.com';

    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
               <h1>Project: GimmeFeels</h1>
                <img style={{marginLeft:"auto",marginRight:"auto",borderRadius:"7px",maxWidth:"80%"}} src={"/img/gimmefeels.png"} alt={"GimmeFeels"}/>
            </div>
        </div>
    </div>
}


export default GimmeFeels;