import React from "react";
import {BsArrowRightCircleFill} from "react-icons/bs";
import {FaEthereum} from "react-icons/fa";
import {SiChainlink} from 'react-icons/si';
import HomeBoxes from "../assets/HomeBoxes";

const GoerliFaucet = () => {
    window.document.title = 'Faucets >> BlokChainr.com';

    let resources = [
        {
            title: "ETH",
            content: ["ETH Goerli Proof of Work Faucet for Developers.  This is a Ethereum Faucet running on the Goerli Testnet.  To prevent malicious actors from exhausting all available funds or accumulating enough Ether to mount long running spam attacks, this faucet requires some mining work to be done in exchange for free testnet funds.",
                <a href="https://goerli-faucet.pk910.de/" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <FaEthereum/>
        },
        //https://faucets.chain.link/
        {
            title: "Chainlink",
            content: ["Get testnet LINK for an account on one of the supported blockchain testnets so you can create and test your own oracle and Chainlinked smart contract. ",
                <a href="https://faucets.chain.link/" rel="noreferrer" target="_blank"><BsArrowRightCircleFill/></a>],
            icon: <SiChainlink/>
        },
    ];
    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
                <h1>Faucets</h1>
                <HomeBoxes boxes={resources}/>
            </div>
        </div>
    </div>
}


export default GoerliFaucet;