async function Mailer(props) {
    let role = props.role;
    let name = props.name;
    let company = props.company;
    let website = props.website;
    let email = props.email;
    let phone = props.phone;
    let message = props.message;
    let pref = props.pref;

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({role: role, name: name, company: company, email: email, phone: phone, message: message, pref: pref, website: website})
    };

    let url = 'https://api.blokchainr.com/mailer';

    return await fetch(url, requestOptions);

}

export default Mailer;