import React, {useEffect, useState} from "react";
import FormField from "./FormField";
import Mailer from "../utils/Mailer";
import ReCAPTCHA from "react-google-recaptcha";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ContactForm = (props) => {
    let submitButton = props.submit ?? "Let's Chat!";
    const [contactSubmit, setContactSubmit] = useState(submitButton);
    const [recaptcha, setRecaptcha] = useState(false);

    const changeCanSend = (value) => {
        setRecaptcha(value);
    }
    let fields = [
        {
            label: "What's your name?", type: "input", name: "name", placeholder: "e.g Steve Hobbs", required: true
        },
        {
            label: "What's your email?",
            type: "input",
            name: "email",
            placeholder: "e.g steve@micro-hobs.app",
            required: true
        },
        {
            label: "What's your phone (+ any dialing codes)?",
            type: "input",
            name: "phone",
            placeholder: "e.g +44 0208 4567 345"
        },
        {
            label: "What's your preferred way of contact?",
            type: "select",
            name: "pref",
            options: [{value: "Email"}, {value: "Phone"}, {value: "WhatsApp"}, {value: "Slack"}, {value: "Discord"}]
        },
        {
            label: "What’s the name of your project/company?",
            type: "input",
            name: "company",
            placeholder: "Micro-Hobs Limited",
            required: true
        },
        {
            label: "What’s the website of your project/company?",
            type: "input",
            name: "website",
            placeholder: "e.g https://www.micro-hobs.app"
        },
        {
            label: "What role best describes you?", type: "select", name: "role", options: [
                {value: "Business Development/Sales"},
                {value: "Blockchain Developer"},
                {value: "Developer"},
                {value: "Engineering"},
                {value: "Co-Founder/CEO"},
                {value: "Co-Founder/CTO"},
                {value: "Operations"},
                {value: "Other"}
            ]
        },
        {
            label: "Start the Conversation?",
            type: "text",
            name: "message",
            placeholder: "Please contact me regarding a future project!"
        },
    ];


    const doContact = (event) => {
        event.preventDefault();
        const myFormData = new FormData(event.target);
        const form = {};
        myFormData.forEach((value, key) => (form[key] = value));
        if (recaptcha) {
            Mailer(form).then(resp => {
                return resp.json();
            }).then(json => {
                if (json.sent) {
                    setContactSubmit("Sent");
                } else {
                    setContactSubmit("Error");
                }
            }).catch(err => {
                setContactSubmit("problem")
            });
        } else {
            setContactSubmit("Mr Robot??")
        }

    }

    useEffect(() => {
        const stop = e => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }

        }
        let elements = document.getElementsByClassName('enter-2-tab');

        let i;
        for (i = 0; i < elements.length; i++) {
            elements[i].addEventListener("keydown", stop);
        }

        return () => {
            let elements = document.getElementsByClassName('enter-2-tab');

            let i;
            for (i = 0; i < elements.length; i++) {
                elements[i].removeEventListener("keydown", stop);
            }
        };

    }, []);

    return <div className={"contact-form-holder"}>
        <div><h1>Contact</h1></div>
<div>
        <form onSubmit={e => doContact(e)}>
            <div className={"contact-fields"}>
                {fields.map((a) => FormField(a))}
            </div>
            <div className={"contact-submit"}>
                <div className={"recaptcha-holder"}>
                    <ReCAPTCHA
                        size={"normal"}
                        sitekey="6LeY22IiAAAAAFfHpYMzJtyUhq8sUq_o0vc7vHza"
                        onChange={changeCanSend}
                    />
                </div>
                <button type={"submit"} className={"call-to-action"}>{contactSubmit}</button>
            </div>
        </form>
</div>
    </div>
}

export default ContactForm;