import React, {useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {SiChainlink, SiSolidity, SiBitcoin} from 'react-icons/si';
import {FaEthereum} from 'react-icons/fa';
import Particles from "react-tsparticles";
import {loadFull} from "tsparticles";
import {options} from "./particleOptions";
import {Video} from "./Video";

const Banner = ({content, video}) => {

    let navigate = useNavigate();
    const navigateTo = (page) => {
        navigate(page);
    }
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);

    }, []);



    //https://www.npmjs.com/package/react-tsparticles
    return <div className="banner">
        <Particles
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                minHeight: "100%",
                minWidth: "100%",
                zIndex: 0,
                opacity: 0.3,
            }}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={options}
        />
        <div className="banner-title" style={{userSelect: "none"}}>{content ?? <>Creating the Future
            with <b>A.I</b> & <b>Web3</b></>}</div>
        <div className="main-image"></div>
        <Video />
        <div className={"call-to-action"} onClick={() => navigateTo('/contact')}>Get Started</div>
        <div className="icons"><SiBitcoin/><FaEthereum/><SiChainlink/><SiSolidity/></div>
    </div>
}

export default Banner