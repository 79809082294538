import React from "react";

const Privacy = () => {
    window.document.title = 'Privacy >> BlokChainr.com';

    return <div className={"page"}>
        <div className={"layout"}>
            <div className={"content"}>
                <iframe className={"site-frame"} title="privacy" src={"/privacy.html"} />
            </div>
        </div>
    </div>
}


export default Privacy;